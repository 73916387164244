import { useRouter } from 'next/router'
import { Avatar, Link, StackProps, useTheme } from '@mui/material'

import VerifiedIcon from 'components/commons/CreatorCaption/VerifiedIcon'
import { IProfileData } from 'stores/domains/User/UserProfile'
import trackEvent from 'trackers'

import IconKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { Container, Subtitle, Title } from './styled'
import CommonStack from 'components/commons/Stack'

export interface MenuProfileProps extends StackProps {
  profileData: IProfileData
  fullWidth?: boolean
  dark?: boolean
  sidebar?: boolean
}

export function MenuProfile({
  profileData,
  fullWidth = false,
  sidebar = false,
  ...rest
}: MenuProfileProps) {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }

  const theme = useTheme()
  const { creator, isCreator, username, role } = profileData
  const name = profileData?.callName

  const userCallName = () => {
    if (!fullWidth || !sidebar) return name
    if (creator?.verified) {
      return name?.substring(0, 8)
    }
    return name?.substring(0, 10)
  }

  const handleClickMenu = () => {
    const eventName = isCreator
      ? 'click_my_creator_profile'
      : 'click_my_supporter_profile'

    const modalStates =
      !fullWidth && !sidebar
        ? { modal_name: 'USER_NAVIGATION_MENU' }
        : {}

    trackEvent.user(eventName, trackerData, { ...modalStates })
  }

  return (
    <Link
      sx={{ textDecoration: 'none' }}
      href={`/p/${username}?source=/menu&source_action=${
        isCreator
          ? 'click_my_creator_profile'
          : 'click_my_supporter_profile'
      }`}
    >
      <Container
        width={fullWidth ? '100%' : '312px'}
        id="b-users-view-profile-button"
        onClick={handleClickMenu}
        {...rest}
      >
        <Avatar
          alt={name}
          src={profileData?.avatar}
          sx={{
            width: 48,
            height: 48,
            background: theme.palette.info.main,
          }}
        />
        <CommonStack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
          sx={{
            overflow: 'hidden',
          }}
        >
          <CommonStack direction="column">
            <Title>
              {userCallName()}
              {isCreator && Boolean(creator?.verified) && (
                <VerifiedIcon size="sm" />
              )}
            </Title>
            <Subtitle variant="p">{role}</Subtitle>
          </CommonStack>
          <IconKeyboardArrowRight fontSize="small" color="tertiery" />
        </CommonStack>
      </Container>
    </Link>
  )
}
