import { Stack, SxProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { useStoreActions } from 'stores/hooks'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getLocalStorage } from 'helpers/local-storage'
import isEmpty from 'helpers/is-empty'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MessageBox from '../MessageBox'
import { useReferralStore } from 'stores/domains/User'
import { useReferralInfo } from 'hooks/domains/User/useReferral'
import { useTranslation } from 'react-i18next'

type IStackProps = {
  fixed: string
}

const StyledStack = styled(Stack)<IStackProps>(
  ({ theme, fixed }) => ({
    background: theme.palette.background.tertiery,
    borderRadius: '6px',
    width: fixed === 'true' ? '92%' : '100%',
    bottom: '24px',
    position: fixed === 'true' ? 'absolute' : 'inherit',
    [theme.breakpoints.up('sm')]: {
      width: fixed === 'true' ? '450px' : '100%',
      position: fixed === 'true' ? 'absolute' : 'inherit',
    },
    minHeight: '52px',
    padding: '8px',
    svg: {
      color: theme.palette.info.main,
    },
    margin: fixed ? '16px 0px' : '0px',
  }),
)

const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallBold,
  color: theme.palette.text.primary,
}))

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  color: theme.palette.text.primary,
  marginTop: '4px',
}))

type IReferralCodeInfoProps = {
  isFixed?: boolean
  isChecked?: boolean
  containerStyle?: React.CSSProperties | SxProps
}

const ReferralCodeInfo = ({
  isFixed,
  isChecked,
  containerStyle,
}: IReferralCodeInfoProps) => {
  const referralCode = getLocalStorage('referralCode')
  const { t } = useTranslation()
  // hooks
  const { isReady } = useRouter()
  const {
    refetch: fetchReferralCodeInfo,
    isFetching,
    isError,
  } = useReferralInfo(referralCode?.code)

  // store
  const { setShowReferralCodeDrawer } = useStoreActions(
    (action) => action.referralCode,
  )
  const { referralCodeInfo, errorMessage, clearReferralCodeInfo } =
    useReferralStore((state) => ({
      referralCodeInfo: state.referralCodeInfo,
      errorMessage: state.errorMessage,
      clearReferralCodeInfo: state.clearReferralCodeInfo,
    }))

  const handleClickShowReferralCodeDrawer = () => {
    setShowReferralCodeDrawer(true)
  }

  useEffect(() => {
    if (
      isReady &&
      !isEmpty(referralCode) &&
      isEmpty(referralCodeInfo?.user?.name)
    ) {
      fetchReferralCodeInfo()
    }
  }, [isReady])

  return (
    <>
      {!isFetching && !isError && referralCodeInfo?.user?.name && (
        <StyledStack
          fixed={isFixed ? 'true' : 'false'}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={containerStyle}
        >
          <Stack direction="row" alignItems="center">
            {isChecked ? <CheckCircleIcon /> : <InfoIcon />}

            <Stack
              style={{
                marginLeft: '8px',
                width: '100%',
                marginRight: '24px',
              }}
            >
              <StyledTitle>{t('referralSnippetTitle')}</StyledTitle>
              <StyledSubtitle sx={{ width: '100%' }}>
                {referralCodeInfo.user.referral_code} a/n{' '}
                {referralCodeInfo.user.name}
              </StyledSubtitle>
            </Stack>
          </Stack>
          {isChecked && (
            <StyledTitle
              sx={(theme) => ({
                marginRight: '24px',
                cursor: 'pointer',
                color: theme.palette.primary.main,
              })}
              onClick={handleClickShowReferralCodeDrawer}
            >
              <b>{t('change')}</b>
            </StyledTitle>
          )}
        </StyledStack>
      )}

      {!isFetching && isError && !isEmpty(errorMessage) && (
        <MessageBox
          open={!isEmpty(errorMessage)}
          onClose={clearReferralCodeInfo}
          isError={true}
          message={errorMessage}
        />
      )}
    </>
  )
}

export default ReferralCodeInfo
