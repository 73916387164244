import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { useStoreActions } from 'stores/hooks'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useRouter } from 'next/router'
import trackEvent from 'trackers'
import { useProfileUserStore } from 'stores/domains/User'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.info.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: 8,
  borderRadius: '6px',
  margin: '0px 16px 16px 16px',
  svg: {
    color: theme.palette.purple[300],
    marginRight: 8,
  },
  cursor: 'pointer',
}))

const StyledDesc = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  p: {
    ...theme.typography.smallRegular,
    color: 'white',
  },
}))

const StyledArrowRightContainer = styled(Box)({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  svg: {
    color: `white !important`,
    marginRight: 8,
  },
})

function ReferralCodeInfo() {
  const { t } = useTranslation()

  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))
  const { setShowReferralCodeDrawer } = useStoreActions(
    (actions) => actions.referralCode,
  )

  const handleClickShowReferralCodeDrawer = () => {
    trackEvent.user('click_referral_code_fill_in', trackerData)
    setShowReferralCodeDrawer(true)
  }

  return (
    <>
      {!profileData?.referrer_code && (
        <StyledContainer onClick={handleClickShowReferralCodeDrawer}>
          <StyledDesc>
            <InfoIcon />
            <Typography>{t('inputReferralCTA')}</Typography>
          </StyledDesc>
          <StyledArrowRightContainer>
            <ArrowForwardIcon
              sx={{ width: '16px', height: '16px' }}
            />
          </StyledArrowRightContainer>
        </StyledContainer>
      )}
    </>
  )
}
export default ReferralCodeInfo
