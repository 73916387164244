import { ReactNode } from 'react'
import {
  Dialog,
  IconButton,
  SxProps,
  styled,
  useTheme,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from '@mui/material'

import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import Box from 'components/commons/Box'

const Modal = styled(Dialog)(({ theme, sx }) => ({
  '.MuiDialog-paper': {
    borderRadius: '8px',
    background: theme.palette.background.secondary,
    margin: '0px',
    padding: '27px 20px 20px',
    maxWidth: '500px',
    overflow: 'hidden',
    width: '100%',
    maxHeight:
      typeof window !== 'undefined' ? window?.innerHeight : 1080,
    '& .MuiDialogContent-root': {
      padding: '0px',
    },
    ...sx,
  },
}))

export const ModalTitle = styled(Stack)(({ theme }) => ({
  ...theme.typography.h3,
  marginBottom: '10px',
}))

export const ModalTitleContainer = styled(Box)({
  width: '100%',
  minWidth: '280px',
})

const CloseDrawerButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 'bold',
  letterSpacing: '0.02em',
  padding: '0px',
}))

interface IModalDialogProps {
  onClose?: any
  open: boolean
  children: any
  actions?: any
  title?: string | ReactNode
  centerTitle?: boolean
  isNoCloseButton: boolean
  sx?: SxProps
  closeButtonIcon?: any
  renderTitle?: boolean
  titleStartAdornment?: ReactNode
  fullScreen?: boolean
}

function ModalDialog(props: IModalDialogProps) {
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )
  return (
    <Modal
      open={props.open}
      onClose={!props.isNoCloseButton ? props.onClose : () => {}}
      sx={props.sx}
      fullScreen={props.fullScreen}
      disableScrollLock
    >
      {props.renderTitle && (
        <ModalTitle direction="row" justifyContent="space-between">
          {props.titleStartAdornment}
          <ModalTitleContainer
            sx={props.centerTitle && { margin: '0px auto' }}
          >
            <Typography
              variant={isDesktopResolution ? 'h4Desk' : 'h4'}
              color={theme.palette.text.primary}
              textAlign={props.centerTitle ? 'center' : 'left'}
            >
              {props.title}
            </Typography>
          </ModalTitleContainer>
          {!props.isNoCloseButton && (
            <CloseDrawerButton onClick={props.onClose}>
              {props.closeButtonIcon || 'Batal'}
            </CloseDrawerButton>
          )}
        </ModalTitle>
      )}
      <DialogContent sx={{ overflow: 'auto' }}>
        {props.children}
      </DialogContent>
      {props.actions && (
        <DialogActions>{props.actions}</DialogActions>
      )}
    </Modal>
  )
}
ModalDialog.defaultProps = {
  centerTitle: false,
  isNoCloseButton: false,
  onClose: () => {},
  renderTitle: true,
}

export default ModalDialog
