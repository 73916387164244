import apiClient from 'helpers/api-client'
import { sendLog } from 'helpers/log'

export const getCreatorProfile = async (
  username: string,
  token?: string,
) => {
  try {
    const url = token
      ? `/user/v1/creator/${username}/profile`
      : `user/guest/v1/creator/${username}/profile`

    const response = await apiClient({
      url,
      method: 'GET',
      token: token,
    })
    return response?.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export const getCreatorRating = async (username: string) => {
  try {
    const response = await apiClient({
      url: `rating/guest/v1/creator/${username}/overall`,
      method: 'GET',
    })
    return response?.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}
