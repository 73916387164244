import { StateCreator } from 'zustand'
import { IReferralDefault, IReferralStore } from './interface'
import { destroyLocalStorage } from 'helpers/local-storage'

const INITIAL_DATA: IReferralDefault = {
  referralCodeInfo: {
    user: {
      name: '',
      referral_code: '',
    },
    community: {
      mask_id: '',
      name: '',
      is_private: false,
      status_join: 1,
      has_screening_questions: false,
      thumbnail_image: '',
      status: 1,
    },
    is_guest: false,
    is_new_user: false,
  },
  errorMessage: '',
  showReferralInfoDrawer: false,
  showReferralExistDrawer: false,
}

export const createReferralStore: StateCreator<IReferralStore> = (
  set,
) => {
  return {
    ...INITIAL_DATA,
    setReferralCodeInfo(data) {
      set({
        referralCodeInfo: data,
      })
    },
    clearReferralCodeInfo() {
      destroyLocalStorage('referralCode')
      set({
        referralCodeInfo: INITIAL_DATA.referralCodeInfo,
        errorMessage: '',
      })
    },
    setErrorMessage(data) {
      set({
        errorMessage: data,
      })
    },
    setShowReferralInfoDrawer(data) {
      set({
        showReferralInfoDrawer: data,
      })
    },
    setShowReferralExistDrawer(data) {
      set({
        showReferralExistDrawer: data,
      })
    },
  }
}
