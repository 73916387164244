import { useMemo, useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
// styling
import { StyledDivider } from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import {
  StyledHelpIcon,
  StyledIconImg,
  StyledIconRadio,
  StyledInfoRadio,
  StyledWrapperInfo,
  TopUpButton,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
// component
import {
  IconJcb,
  IconMasterCard,
  IconVisa,
} from 'containers/domains/Payment/CardForm/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Box from 'components/commons/Box'
// lib
import { IProductsProps } from 'services/payment'
import theme from 'theme'
import { IPaymentMethod as IPaymentMethodV1 } from 'stores/domains/Wallet/models/TopUp/interface'
import { IPaymentMethod as IPaymentMethodV2 } from 'components/domains/Wallet/FeesPaymentDetail'
import PaymentInfoModal from '../PaymentInfoModal'
import { useTranslation } from 'react-i18next'

export interface ICartData {
  invoice_id?: string
  products: Array<IProductsProps>
  payment_method: Array<IPaymentMethodV1> | Array<IPaymentMethodV2>
}
interface IPaymentMethodRadio {
  paymentType: number
  cartValues: ICartData
  handlePaymentTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void
  topUpCoinHandler?: () => void
  isPaymentLoading: boolean
  isPageView?: boolean
  tokenizationData?: any
  setModalCardForm?: Function
}
export default function PaymentMethodRadio({
  paymentType = 0,
  cartValues = null,
  handlePaymentTypeChange = () => {},
  topUpCoinHandler = () => {},
  isPaymentLoading,
  isPageView,
  tokenizationData,
  setModalCardForm,
}: IPaymentMethodRadio) {
  const { t } = useTranslation()
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const CardIconRender = () => {
    switch (tokenizationData?.card_info?.brand?.toLowerCase()) {
      case 'jcb':
        return <IconJcb cardType={'jcb'} />
      case 'visa':
        return <IconVisa cardType={'visa'} />
      case 'mastercard':
        return <IconMasterCard cardType={'mastercard'} />
      default:
        return ''
    }
  }

  const maskedCardNumber = useMemo(() => {
    return tokenizationData?.masked_card_number.slice(-4)
  }, [tokenizationData])
  const bankName = useMemo(() => {
    return tokenizationData?.card_info?.bank
  }, [tokenizationData])
  const cardType = useMemo(() => {
    return tokenizationData?.card_info?.type.toLowerCase()
  }, [tokenizationData])

  return (
    <FormControl
      style={{
        width: '100%',
      }}
    >
      <RadioGroup
        value={paymentType}
        onChange={handlePaymentTypeChange}
      >
        {cartValues?.payment_method[paymentType] && (
          <PaymentInfoModal
            paymentItem={cartValues?.payment_method[paymentType]}
            openInfo={openInfoModal}
            setCloseInfo={() => {
              setOpenInfoModal(false)
            }}
          />
        )}
        {cartValues?.payment_method.map(
          (paymentItem, paymentItemIndex) => {
            return (
              <>
                <Box
                  key={paymentItemIndex}
                  onClick={() => {
                    if (
                      (cartValues?.payment_method[paymentType]
                        ?.code === 'PAYMENT_CARD' &&
                        paymentItem?.code) === 'PAYMENT_CARD'
                    ) {
                      return setModalCardForm(true)
                    } else {
                      return null
                    }
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: isPageView
                        ? theme.palette.background.tertiery
                        : paymentType === paymentItemIndex &&
                          theme.palette.background.tertiery,
                      marginBottom: isPageView ? '12px' : 0,
                      borderRadius: '6px',
                      padding: '8px 8px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'block',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormControlLabel
                        key={paymentItemIndex}
                        value={paymentItemIndex}
                        control={
                          <Radio disabled={isPaymentLoading} />
                        }
                        label={
                          <StyledIconRadio>
                            <Box
                              sx={{
                                width: '20px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginRight: '10px',
                              }}
                            >
                              <StyledIconImg
                                sx={{
                                  width: '100%',
                                  padding:
                                    paymentItem?.code ===
                                    'TIPTIP_COIN'
                                      ? '2px'
                                      : '0px !important',
                                }}
                                src={paymentItem.black_icon}
                              />
                            </Box>
                            {paymentItem.name}
                            {(paymentItem?.code === 'TIPTIP_COIN' ||
                              paymentItem?.code === 'H2H_BCA_VA' ||
                              paymentItem?.code === 'KREDIVO') && (
                              <StyledHelpIcon
                                onClick={() => {
                                  if (!isPaymentLoading)
                                    setOpenInfoModal(true)
                                }}
                              />
                            )}
                          </StyledIconRadio>
                        }
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                        }}
                      />
                      {cartValues?.payment_method[paymentType]
                        ?.code === 'TIPTIP_COIN' &&
                        paymentItem?.coin?.balance <
                          paymentItem?.coin?.total_price && (
                          <StyledWrapperInfo>
                            <Box sx={{ width: '58px' }} />
                            <StyledInfoRadio
                              sx={{
                                color: theme.palette.error.main,
                              }}
                            >
                              {t('paymentMethodV2WidgetCoinError', {
                                balance: paymentItem?.coin?.balance,
                              })}
                            </StyledInfoRadio>
                          </StyledWrapperInfo>
                        )}
                    </Box>
                    {cartValues?.payment_method[paymentType]?.code ===
                      'TIPTIP_COIN' &&
                      paymentItem?.coin?.balance <
                        paymentItem?.coin?.total_price && (
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 0,
                          }}
                        >
                          <TopUpButton
                            disabled={isPaymentLoading}
                            buttonVariant="filled"
                            onClick={topUpCoinHandler}
                          >
                            {t('topUpCoinButtonTitle')}
                          </TopUpButton>
                        </Box>
                      )}
                  </Box>

                  {paymentType === paymentItemIndex &&
                    tokenizationData &&
                    paymentItem?.code === 'PAYMENT_CARD' && (
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: isPageView
                            ? theme.palette.background.tertiery
                            : paymentType === paymentItemIndex &&
                              theme.palette.background.tertiery,
                          marginBottom: isPageView ? '12px' : 0,
                          borderRadius: '6px',
                          padding: '8px 8px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'block',
                            justifyContent: 'space-between',
                            marginLeft: '16px',
                          }}
                        >
                          <FormControlLabel
                            key={paymentItemIndex}
                            value={paymentItemIndex}
                            control={<Radio />}
                            label={
                              <StyledIconRadio>
                                <Box
                                  sx={{
                                    width: '20px',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginRight: '10px',
                                  }}
                                >
                                  {CardIconRender()}
                                </Box>
                                <Box>
                                  Bank {bankName}
                                  <StyledWrapperInfo>
                                    <StyledInfoRadio
                                      sx={{
                                        color:
                                          theme.palette.text
                                            .secondary,
                                      }}
                                    >
                                      {cardType === 'debit'
                                        ? t('key_debit_card')
                                        : t('key_credit_card')}{' '}
                                      ** {maskedCardNumber}
                                    </StyledInfoRadio>
                                  </StyledWrapperInfo>
                                </Box>
                              </StyledIconRadio>
                            }
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 20,
                              },
                            }}
                          />
                        </Box>
                        <ArrowForwardIosIcon
                          onClick={() => {
                            setModalCardForm(true)
                          }}
                          style={{
                            cursor: 'pointer',
                            color: theme.palette.text.secondary,
                            fontSize: 12,
                            marginRight: 16,
                          }}
                        />
                      </Box>
                    )}

                  {!isPageView && <StyledDivider />}
                </Box>
              </>
            )
          },
        )}
      </RadioGroup>
    </FormControl>
  )
}
