import appConfig from 'configs/app'
import { redirectWithReferrer } from 'helpers/redirector'

export const contentRoleButton = [
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_community_owner.png`,
    title: 'communityOwner',
    subtitle: 'communityOwnerBenefitDesc',
    id: 'b-user-community-owner-role',
    type: 'community',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_creator.png`,
    title: 'creator',
    subtitle: 'creatorBenefitDesc',
    id: 'b-user-creator-role',
    type: 'creator',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_business_entity.png`,
    title: 'business',
    subtitle: 'businessBenefitDesc',
    id: 'b-user-business-entity-role',
    type: 'business',
  },
]

export const communityRoleContent = [
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_sell_ticket.webp`,
    desc: 'communityOwnerDesc1',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_manage_registration.webp`,
    desc: 'communityOwnerDesc2',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_create_session.webp`,
    desc: 'communityOwnerDesc3',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_brand_collab.webp`,
    desc: 'communityOwnerDesc4',
  },
]

export const businessEntityRoleContent = [
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_sell_ticket.webp`,
    desc: 'businessDesc1',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_manage_registration.webp`,
    desc: 'businessDesc2',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_create_session.webp`,
    desc: 'businessDesc3',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_brand_collab.webp`,
    desc: 'businessDesc4',
  },
]

export const creatorRoleContent = [
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_creator_ticket.webp`,
    desc: 'creatorDesc1',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_creator_session.webp`,
    desc: 'creatorDesc2',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_sell_content.webp`,
    desc: 'creatorDesc3',
  },
  {
    icon: `${appConfig.assetBaseUrl}/onboarding/ic_creator_chart.webp`,
    desc: 'creatorDesc4',
  },
]

export const drawerContent = {
  COMMUNITY: {
    title: 'communityOwner',
    subtitle: 'communityOwnerDesc',
    content: communityRoleContent,
    image: `${appConfig.assetBaseUrl}/onboarding/ic_community_owner.png`,
    submitButtonId: 'b-user-create-community-role',
    onSubmit: () =>
      redirectWithReferrer(
        `${appConfig.communityRegistrationUrl}/detail`,
      ),
    buttonTitle: 'createCommunityNow',
  },
  CREATOR: {
    title: 'creator',
    subtitle: 'creatorDesc',
    content: creatorRoleContent,
    image: `${appConfig.assetBaseUrl}/onboarding/ic_creator@hd.png`,
    submitButtonId: 'b-user-create-creator-role',
    onSubmit: () =>
      redirectWithReferrer(
        `${appConfig.creatorRegistrationUrl}?role_type=creator`,
      ),
    buttonTitle: 'becomeCreatorNow',
  },
  BUSINESS: {
    title: 'business',
    subtitle: 'businessBenefitDesc',
    content: businessEntityRoleContent,
    image: `${appConfig.assetBaseUrl}/onboarding/ic_business_entity.png`,
    submitButtonId: 'b-user-create-business-entity-role',
    onSubmit: () =>
      redirectWithReferrer(
        `${appConfig.businessRegistrationUrl}?role_type=business`,
      ),
    buttonTitle: 'becomeBusinessNow',
  },
}
