import { useEffect, useState } from 'react'
import appConfig from 'configs/app'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  CoinInfoContainer,
  ErrorDesc,
  ErrorLabel,
  MainTypography,
  StyledButtonVoucher,
  StyledCheck,
  StyledDivider,
  StyledErrorMessage,
  StyledInputVoucher,
  StyledInputWrapper,
  StyledLink,
  StyledSuccessMessage,
  SubTypography,
} from './styled'
import { CircularProgress, IconButton, Skeleton } from '@mui/material'
import htmlParse from 'html-react-parser'
import VoucherCard, {
  IVoucherCard,
} from 'components/domains/User/VoucherCard'
import trackEvent from 'trackers'
import {
  eventVoucherCheck,
  voucherCheck,
  voucherInbox,
} from 'services/campaign'
import { IProductsProps } from 'services/payment'
import { getIdToken } from 'helpers/auth'
import { ICommunity } from 'stores/domains/Social/Community/interface'
import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import { useTranslation } from 'react-i18next'
import { sendLog } from 'helpers/log'
import { IVoucherCheck } from 'stores/domains/User/models/Voucher/interface'

function numberFormatter(num) {
  return new Intl.NumberFormat('id-ID').format(num)
}

function VoucherSkeleton() {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        height={211}
        width={'100%'}
        style={{
          marginTop: 16,
          background: 'lightgrey',
          borderRadius: '8px',
        }}
      />
    </Box>
  )
}
interface IVoucherInbox {
  handleVoucherClear: Function
  trackerData?: any
  page?: boolean
  selectedProducts?: {
    products: Array<IProductsProps>
    payment_method: string
    checkout_source: string
    user_mask_id?: string
    voucher_code?: string
    idempotentKey: string
  }
  idempotentKey: string
  itemType: string
  paymentType?: string
  bookingId?: string
  community: ICommunity
  voucherData: IVoucherCheck
  setVoucherData: Function
  voucherDetails: IVoucherCard
  setVoucherDetails: Function
  isErrorVoucher: boolean | any
  setIsErrorVoucher: Function
}

export default function VoucherInbox({
  handleVoucherClear,
  trackerData,
  page,
  selectedProducts,
  idempotentKey,
  itemType = 'content',
  bookingId,
  paymentType,
  community,
  voucherData,
  setVoucherData,
  voucherDetails,
  setVoucherDetails,
  isErrorVoucher,
  setIsErrorVoucher,
}: IVoucherInbox) {
  const { t } = useTranslation()
  const [showSendButton, setShowSendButton] = useState(false)
  const [voucherList, setVoucherList] = useState([])
  const [isVoucherLoading, setIsVoucherLoading] = useState(false)
  const [inputVoucherCode, setInputVoucherCode] = useState('')

  const handleVoucherChecker = async (voucherName) => {
    try {
      setIsVoucherLoading(true)
      const voucherResponse =
        itemType === 'event'
          ? await eventVoucherCheck({
              book_session_id: bookingId,
              payment_method: paymentType,
              idempotentKey: idempotentKey,
              voucher_code: voucherName.toUpperCase(),
            })
          : await voucherCheck({
              products: selectedProducts?.products,
              voucher_code: voucherName.toUpperCase(),
              payment_method: selectedProducts?.payment_method,
              idempotentKey: idempotentKey,
              checkout_source: selectedProducts?.checkout_source,
            })
      setVoucherData(voucherResponse)
      voucherTracker('click_voucher_code_fill_in', voucherName)
      setInputVoucherCode('')
      setIsVoucherLoading(false)
      setVoucherDetails(null)
    } catch (err) {
      setIsErrorVoucher(err)
      sendLog(err)
    } finally {
      setIsVoucherLoading(false)
    }
  }

  useEffect(() => {
    const handleVoucherInboxV2 = async () => {
      try {
        setIsVoucherLoading(true)
        setVoucherList(
          await voucherInbox({
            products: selectedProducts.products,
            payment_method: selectedProducts?.payment_method,
            idempotentKey: idempotentKey,
            checkout_source: selectedProducts?.checkout_source,
          }),
        )
        setIsVoucherLoading(false)
      } catch (err) {
        sendLog(err)
      } finally {
        setIsVoucherLoading(false)
      }
    }
    if (page && selectedProducts) {
      handleVoucherInboxV2()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts])

  function voucherDetailsHandler(data) {
    setVoucherDetails(data)
  }
  function tooglerShowHideButton() {
    setShowSendButton(!showSendButton)
  }
  const handleVoucherCodeChange = (event) => {
    setInputVoucherCode(event.target.value.toUpperCase())
  }

  const voucherTracker = (eventName, voucherCode) => {
    return trackEvent.payment(
      eventName,
      { ...trackerData, itemType },
      {
        modal_name: `PURCHASE_${itemType.toLocaleUpperCase()}`,
        voucher_code: voucherCode,
        user_type: getIdToken() ? 'supporter' : 'guest',
        event_quantity:
          itemType === 'event'
            ? selectedProducts.products[0].tickets[0].qty
            : '',
        is_member_community: community?.is_member || '',
      },
    )
  }

  const redirectURL = (url) => {
    window.open(url, '_blank')
  }
  const RenderVoucherStatus = () => {
    if (voucherData) {
      return (
        <StyledSuccessMessage>
          Kode voucher berhasil diterapkan. Segera selesaikan
          pembayaran untuk mendapatkan potongan{' '}
          {voucherData?.payment_method[1]?.voucher_info}.
        </StyledSuccessMessage>
      )
    } else if (isErrorVoucher?.response?.data?.code) {
      if (
        isErrorVoucher?.response?.data?.code.toUpperCase() ===
        'NOT_ACCEPTABLE_VOUCHER'
      ) {
        return (
          <StyledErrorMessage>
            Voucher tidak dapat digunakan untuk transaksi ini. Cek
            syarat dan ketentuan berlaku{' '}
            <StyledLink
              onClick={() => {
                voucherTracker(
                  'click_voucher_code_tnc',
                  inputVoucherCode,
                )
                redirectURL(`${appConfig.supportCenterUrl}home`)
              }}
            >
              disini.
            </StyledLink>
          </StyledErrorMessage>
        )
      } else {
        return (
          <StyledErrorMessage>
            {isErrorVoucher?.response?.data?.message}
          </StyledErrorMessage>
        )
      }
    }
  }

  return (
    <>
      {voucherDetails === null ? (
        <Box>
          <MainTypography>
            {t('voucherInboxScreenSearchBarTitle')}
          </MainTypography>
          <SubTypography>
            {t('voucherInboxScreenSearchBarDesc')}
          </SubTypography>
          <StyledInputWrapper>
            <StyledInputVoucher
              border={inputVoucherCode && voucherData}
              isError={isErrorVoucher}
              autoComplete="off"
              onChange={handleVoucherCodeChange}
              onBlur={() => {
                trackEvent.paymentCart(
                  `click_search_box`,
                  trackerData,
                  {
                    keyword_search: inputVoucherCode,
                  },
                )
                tooglerShowHideButton()
              }}
              onFocus={() => {
                tooglerShowHideButton()
              }}
              value={inputVoucherCode}
              placeholder={t('voucherInboxScreenSearchBarHint')}
              startAdornment={
                inputVoucherCode && voucherData && <StyledCheck />
              }
              endAdornment={
                inputVoucherCode && (
                  <IconButton
                    onMouseDown={(event) => {
                      event.preventDefault()
                    }}
                    onClick={() => {
                      setInputVoucherCode('')
                      handleVoucherClear()
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )
              }
            />
            {showSendButton && !isVoucherLoading ? (
              <StyledButtonVoucher
                buttonVariant="filled"
                onMouseDown={(event) => {
                  event.preventDefault()
                }}
                disabled={inputVoucherCode !== '' ? false : true}
                onClick={() => {
                  handleVoucherChecker(inputVoucherCode)
                }}
              >
                {t('voucherInboxScreenButtonApplyVoucher')}
              </StyledButtonVoucher>
            ) : isVoucherLoading ? (
              <CircularProgress style={{ marginLeft: 40 }} />
            ) : (
              ''
            )}
          </StyledInputWrapper>
          <CoinInfoContainer>
            {RenderVoucherStatus()}
          </CoinInfoContainer>

          {voucherList.length > 0 && (
            <MainTypography>
              <StyledDivider />
              {t('key_or_user_voucher')}
            </MainTypography>
          )}
          {isVoucherLoading ? (
            <VoucherSkeleton />
          ) : voucherList.length > 0 && !isVoucherLoading ? (
            voucherList.map((voucherListData, itemIndex) => {
              return (
                <VoucherCard
                  key={itemIndex}
                  voucherListData={voucherListData}
                  voucherChecker={(voucherName) => {
                    handleVoucherChecker(voucherName)
                  }}
                  voucherDetailsHandler={voucherDetailsHandler}
                  isLoading={isVoucherLoading}
                  trackerData={trackerData}
                />
              )
            })
          ) : (
            <Box>
              <StateImage
                type="error"
                sx={{
                  marginBottom: '16px',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
              <ErrorLabel>
                Belum ada voucher untuk digunakan
              </ErrorLabel>
              <ErrorDesc>
                Coba kembali lagi nanti ya, atau coba masukkan kode
                promo.
              </ErrorDesc>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <VoucherCard
            voucherListData={voucherDetails}
            voucherChecker={handleVoucherChecker}
            isLoading={isVoucherLoading}
          />
          <MainTypography>{voucherDetails?.name}</MainTypography>
          <SubTypography>
            <li>
              Potongan sampai dengan{' '}
              {numberFormatter(voucherDetails?.max_discount_amount)}
            </li>
          </SubTypography>
          <StyledDivider />
          <MainTypography>
            {htmlParse(voucherDetails?.description)}
          </MainTypography>
        </Box>
      )}
    </>
  )
}
