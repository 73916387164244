import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { IActionUserDevice } from 'constants/user'
import { isGuestMode } from 'helpers/cookie'
import { sendLog } from 'helpers/log'

export const requestForgotPassword = async (email: string) => {
  return await apiClient({
    url: '/user/guest/v1/forgot-password',
    method: 'POST',
    data: {
      username: email,
    },
  })
}

export const updateUserDevice = async (
  fcmToken: string,
  action: IActionUserDevice,
) => {
  return await apiClient({
    url: '/user/v1/device',
    method: 'PUT',
    data: {
      fcm_token_id: fcmToken,
      action,
    },
    token: getIdToken(),
  })
}

export const getPhoneVerified = async (token?: string) => {
  if (isGuestMode()) return {}
  const response = await apiClient({
    url: '/user/v1/phone',
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const getCategories = async () => {
  return await apiClient({
    url: '/user/guest/v2/categories',
    method: 'GET',
  })
}

export const getPopularCreator = async (params) => {
  return await apiClient({
    url: '/user/guest/v2/popular',
    method: 'GET',
    params,
  })
}

export const getReferralCodeInfo = async (referral_code: string) => {
  return await apiClient({
    url: isGuestMode()
      ? `/user/guest/v1/referral-code/${referral_code}/info`
      : `/user/v1/referral-code/${referral_code}/info`,
    method: 'GET',
    token: getIdToken(),
  })
}

export const getSupportedCountries = async () => {
  return await apiClient({
    url: '/user/guest/v1/supported-countries',
    method: 'GET',
  })
}

export const getFollowers = async (username: string) => {
  try {
    return await apiClient({
      url: getIdToken()
        ? `/user/v1/follows/${username}`
        : `/user/guest/v1/follows/${username}`,
      method: 'GET',
      token: getIdToken(),
    })
  } catch (error) {
    sendLog(error)
    throw error
  }
}

interface IDataFollow {
  username: string
  following: boolean
}

export const postFollow = async (data: IDataFollow) => {
  const token = getIdToken()
  if (!token) return {}
  const response = await apiClient({
    url: `/user/v1/follows/${data.username}`,
    method: 'POST',
    data: {
      creator_username: data.username,
      set_following: data.following,
    },
    token,
  })
  return response?.data
}
