import { Alert, Collapse, styled, SxProps } from '@mui/material'
import theme from 'theme'
import Box from 'components/commons/Box'
import CommonTypography from 'components/commons/Typography'
import WarningIcon from '@mui/icons-material/Warning'

const Container = styled(Box)({
  width: '100%',
  maxWidth: 1152,
  padding: '0px 16px',
  margin: '16px auto',
})

interface ITimeoutPaymentBannerProps {
  isOpen: boolean
  desc?: string
  sx?: SxProps
}

export default function TimeoutPaymentBanner({
  isOpen,
  desc,
  sx,
}: ITimeoutPaymentBannerProps) {
  return (
    <Container
      sx={{
        ...sx,
      }}
      data-testid="timeout-payment-banner"
    >
      <Collapse in={isOpen}>
        <Alert
          variant="outlined"
          severity="warning"
          sx={{
            color: theme.palette.text.primary,
            width: '100%',
            padding: 1,
            fontSize: 12,
            margin: '16px 0px',
          }}
          icon={<WarningIcon style={{ margin: 'auto' }} />}
        >
          <CommonTypography>{desc}</CommonTypography>
        </Alert>
      </Collapse>
    </Container>
  )
}
