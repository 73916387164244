import Stack from 'components/commons/Stack'
import ModalDrawer from '../ModalDrawer'
import StateImage from 'components/commons/StateImage'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'
import ActionButton from 'components/commons/Button/Action'
import Button from 'components/commons/Button'

import CloseIcon from '@mui/icons-material/Close'
import {
  Checkbox,
  FormControlLabel,
  styled,
  useTheme,
} from '@mui/material'
import { MouseEventHandler, useState } from 'react'

import appConfig from 'configs/app'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { CreatorClass } from 'stores/domains/User/UserProfile'

export const StyledNote = styled('div')(({ theme }) => ({
  margin: '0px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  fontSize: theme.typography.smallRegular.fontSize,
  color: theme.palette.text.secondary,
  ul: {
    margin: '0px',
    paddingLeft: '0px',
    listStyleType: 'none',
  },
}))

interface IRoleDrawerProps {
  isOpen: boolean
  onClose: MouseEventHandler<HTMLButtonElement>
  image: string
  title: string
  subtitle: string
  content: Array<any>
  onSubmit: MouseEventHandler<HTMLButtonElement>
  submitButtonId: string
  buttonTitle: string
  activeDrawer: CreatorClass
  isFromMenu?: boolean
}

const RoleDrawer = ({
  isOpen,
  onClose,
  image,
  title,
  subtitle,
  content,
  onSubmit,
  submitButtonId,
  buttonTitle,
  activeDrawer,
  isFromMenu,
}: IRoleDrawerProps) => {
  const { palette, breakpoints } = useTheme()
  const { t } = useTranslation()
  const [isCheckedConfirmation, setCheckedConfirmation] = useState(
    false || undefined,
  )

  const handleCloseDrawer = (e) => {
    onClose(e)
    setCheckedConfirmation(false)
  }

  return (
    <ModalDrawer
      isOpen={isOpen}
      title=""
      onClose={(e) => {
        handleCloseDrawer(e)
      }}
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: 0,
          borderRadius: '24px',
        },
        '& .MuiDialog-paper': {
          padding: 0,
          backgroundImage: `url(${appConfig.assetBaseUrl}/onboarding/frame_drawer@2x.png) !important`,
          backgroundSize: 'contain !important',
          backgroundRepeat: 'no-repeat !important',
          backgroundPosition: 'top !important',
        },
      }}
      sxDrawerContainer={{
        margin: 0,
        borderRadius: '12px 12px 0px 0px',
        backgroundImage: `url(${appConfig.assetBaseUrl}/onboarding/frame_drawer@2x.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
      }}
      hideCloseButton
    >
      <Stack
        sx={{
          padding: '8px 16px 24px',
          justifyContent: 'center',
        }}
        spacing={'12px'}
      >
        <Button
          sx={{
            minWidth: 'auto',
            padding: '0px !important',
            alignSelf: 'flex-end',
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
          buttonVariant="nude"
          onClick={handleCloseDrawer}
          id="b-user-close-drawer"
          data-testid="b-user-close-drawer"
        >
          <CloseIcon
            sx={{
              alignSelf: 'flex-end',
              color: palette.primary.main,
            }}
          />
        </Button>
        <Box sx={{ textAlign: 'center' }}>
          <StateImage
            src={image}
            alt={`state-image-role`}
            sx={{
              width: '120px',
              height: 'auto',
              objectFit: 'cover',
              [breakpoints.up('sm')]: {
                paddingTop: '24px',
              },
            }}
          />
          <Typography
            sx={{
              typography: { xs: 'h4', md: 'h4Desk' },
              marginBottom: '4px',
              marginTop: '24px',
              [breakpoints.up('sm')]: {
                marginTop: '40px',
              },
            }}
          >
            {title}
          </Typography>
          <Typography variant="normalRegular">{subtitle}</Typography>
        </Box>

        <Stack
          spacing={2}
          sx={{
            padding: '16px',
            boxShadow: `0px 0px 12px 0px ${palette.border.minimal}`,
            borderRadius: '12px',
          }}
        >
          <Typography variant="normalBold">
            {t('whatRoleCanDo', { role: title })}
          </Typography>
          {content.map((item, index) => (
            <Stack
              key={index}
              direction={'row'}
              spacing={'12px'}
              alignItems={'center'}
            >
              <StateImage
                src={item.icon}
                sx={{ width: '32px', height: '32px' }}
              />
              <Typography variant="normalRegular">
                {t(item.desc)}
              </Typography>
            </Stack>
          ))}
        </Stack>

        {activeDrawer === 'BUSINESS' && (
          <Stack gap={2}>
            {isFromMenu && (
              <Stack
                direction={'row'}
                gap={2}
                sx={{
                  padding: '8px',
                  alignItems: 'center',
                  border: `1px solid ${palette.info.main}`,
                  borderRadius: '6px',
                }}
              >
                <InfoIcon
                  color="info"
                  sx={{
                    fontSize: '24px',
                  }}
                />
                {t('additionalInfoBusiness')}
              </Stack>
            )}

            <Stack
              sx={{
                padding: `12px 16px`,
                borderRadius: `12px`,
                border: `1px solid ${palette.grey[300]}`,
                background: palette.background.secondary,
              }}
            >
              <FormControlLabel
                key={`business-npwp-confirmation`}
                control={
                  <Checkbox
                    id={'business-entity-confirmation-checkbox'}
                    checked={isCheckedConfirmation || false}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      setCheckedConfirmation(event.target.checked)
                    }}
                    sx={{
                      color: palette.text.tertiery,
                      paddingTop: 0,
                      paddingBottom: 0,
                      '&.Mui-checked': {
                        color: palette.primary.main,
                      },
                      '&.Mui-disabled': {
                        color: palette.text.tertiery,
                        WebkitTextFillColor: palette.text.tertiery,
                      },
                    }}
                  />
                }
                label={t('consentTextBusiness')}
              />
            </Stack>
          </Stack>
        )}

        <ActionButton
          id={submitButtonId}
          onClick={onSubmit}
          disabled={
            activeDrawer === 'BUSINESS' && !isCheckedConfirmation
          }
          sx={{ marginTop: '32px !important' }}
        >
          {buttonTitle}
        </ActionButton>

        {activeDrawer === 'BUSINESS' && isFromMenu && (
          <ActionButton
            buttonVariant="outlined"
            id={'b-user-business-entity-confirmation-cancel'}
            onClick={handleCloseDrawer}
            sx={{ marginTop: '32px !important' }}
          >
            {t('key_cancel')}
          </ActionButton>
        )}
      </Stack>
    </ModalDrawer>
  )
}

export default RoleDrawer
